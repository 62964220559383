// Matches app/views/site/_hovercard_template.html.erb which is the template used in the rest of the monolith
// This component's contents get set by app/assets/modules/github/behaviors/hovercards.ts

export default function HoverCard() {
  return (
    <div
      className="Popover js-hovercard-content position-absolute"
      style={{display: 'none', outline: 'none'}}
      role="dialog"
      aria-modal
    >
      <div
        className="Popover-message Popover-message--bottom-left Popover-message--large Box color-shadow-large"
        style={{width: '360px'}}
      />
    </div>
  )
}

try{ HoverCard.displayName ||= 'HoverCard' } catch {}