import {
  GitPullRequestIcon,
  GitPullRequestClosedIcon,
  GitPullRequestDraftIcon,
  GitMergeIcon,
  IssueOpenedIcon,
  CheckCircleIcon,
  type Icon,
  SkipIcon,
} from '@primer/octicons-react'

function issueIsOpen(state: string) {
  return state === 'open'
}

export function getIssueIconColor(
  state: string,
  isPullRequest: boolean,
  merged?: boolean,
  reviewableState?: string,
  state_reason?: string,
) {
  if (isPullRequest) {
    if (reviewableState === 'draft') {
      return 'fg.muted'
    } else if (merged) {
      return 'done.fg'
    } else if (issueIsOpen(state)) {
      return 'open.fg'
    } else {
      return 'closed.fg'
    }
  } else if (issueIsOpen(state)) {
    return 'success.fg'
  } else {
    if (state_reason === 'not_planned' || state_reason === 'duplicate') {
      return 'fg.muted'
    }
    return 'done.fg'
  }
}

export function getIssueIcon(
  state: string,
  isPullRequest: boolean,
  state_reason?: string,
  merged?: boolean,
  reviewableState?: string,
): Icon {
  if (isPullRequest) {
    if (reviewableState === 'draft') {
      return GitPullRequestDraftIcon
    } else if (merged) {
      return GitMergeIcon
    } else if (issueIsOpen(state)) {
      return GitPullRequestIcon
    } else {
      return GitPullRequestClosedIcon
    }
  } else {
    if (issueIsOpen(state)) {
      return IssueOpenedIcon
    } else {
      if (state_reason === 'not_planned' || state_reason === 'duplicate') {
        return SkipIcon
      }
      return CheckCircleIcon
    }
  }
}
