import type {SafeHTMLString} from '@github-ui/safe-html'
import type {CertificateAttributes, CommitSignatureType, VerificationStatus} from '@github-ui/signed-commit-badge'
import type {ModelListing} from '@github-ui/marketplace-common'

export type SearchResults = (
  | BlackbirdResults
  | LegacyCodeResults
  | RepositoriesResults
  | CommitsResults
  | IssuesResults
  | PullRequestsResults
  | DiscussionsResults
  | PackagesResults
  | TopicsResults
  | WikisResults
  | UsersResults
  | MarketplaceResults
) &
  SearchResponse

export type SearchResultsType = SearchResults['type']

export interface SearchResponse {
  errors: QueryError[]
  elapsed_millis: number
  result_count: number
  query_id: string
  page_count: number
  page: number
  protected_org_logins?: string[]
  facets: Facet[]
  header_redesign_enabled?: boolean
  logged_in?: boolean
  sign_up_path?: string
  sign_in_path: string
  warn_limited_results?: boolean
}

export interface Facet {
  kind: string
  entries: FacetEntry[]
}

export interface FacetEntry {
  name: string
  owner?: string
  language_color?: string
  query: string
  onClick?: () => void
  shouldNavigate?: boolean
  visual?: React.ReactElement
  searchParams?: {[key: string]: string | null}
}

export interface ErrorRange {
  start: number
  end: number
}

export const PARSE_ERROR_FATAL = 1
export interface QueryError {
  message: string
  type?: number
  ranges?: ErrorRange[]
  suggestion?: string
  missing_or_inaccessible_repo_org_nwo?: string
}

interface BlackbirdSymbol {
  fully_qualified_name: string
  kind: string | number
  ident_start: number
  ident_end: number
  extent_start: number
  extent_end: number
  score: number
}

interface SnippetCommon {
  starting_line_number: number
  ending_line_number: number
  jump_to_line_number: number
  match_count: number
  score: number
}

interface HTMLSnippet extends SnippetCommon {
  lines: readonly SafeHTMLString[]
  format: 'SNIPPET_FORMAT_HTML'
}

interface PlainTextSnippet extends SnippetCommon {
  lines: readonly string[]
  format: 'SNIPPET_FORMAT_PLAIN_TEXT'
}

export type Snippet = HTMLSnippet | PlainTextSnippet

interface Location {
  path: string
  repo_id: number
  repo_nwo: string
  owner_id: number
  commit_sha: string
  ref_name: string
}

export interface CodeResult {
  path: string
  repo_id: number
  repo_nwo: string
  owner_id: number
  commit_sha: string
  ref_name: string
  blob_sha: string
  language_name: string
  language_id: number
  has_language_id: boolean
  language_color: string
  match_count: number
  matched_symbols: BlackbirdSymbol[]
  snippets: Snippet[]
  line_number: number
  duplicate_locations: Location[]
  debug_info?: CodeResultDebugInfo
}

export interface CodeResultDebugInfo {
  score: number
  retrieval_position: number
}

/**
 * Represents code search results from Blackbird.
 */
export interface BlackbirdResults {
  type: 'code'
  results: CodeResult[]
  metadata?: BlackbirdMetadata
}

export interface BlackbirdMetadata {
  total_cost: number
  retries: number
  limit_reached: boolean
  docs_returned: number
}

export interface LegacyCodeResults {
  type: 'codelegacy'
  results: LegacyCodeResultItem[]
}

export interface LegacyCodeResultItem {
  id: string
  path: string
  filename: string
  match_count: number
  explain: null
  /*
   * The file size, in bytes
   */
  file_size: number
  public: boolean
  language?: string | null
  language_color?: string | null
  blob_sha: string
  commit_sha: string
  timestamp: string
  repo_id: number
  repo: {repository: {name: string; [key: string]: unknown}}
  repo_name: string
  repo_default_branch: string
  owner_login: string
  highlights: {
    file: string[]
  }
  /**
   * File contents with syntax highlighting (not just result highlighting)
   */
  hl_path?: SafeHTMLString
  hl_filename?: SafeHTMLString
  hl_fragments: LegacyCodeHighlightFragment[]
}

interface LegacyCodeHighlightFragment {
  lines: LegacyCodeHighlightLine[]
}

interface LegacyCodeHighlightLine {
  line: SafeHTMLString
  line_number: number
}

export interface RepositoriesResults {
  type: 'repositories'
  results: RepositoryResultItem[]
  topics: CuratedTopicResultItem | null
}

export interface RepositoryResultItem {
  archived: boolean
  color: string
  followers: number
  has_funding_file: boolean
  /**
   * HTML escaped name with an <em> around the matching substring.
   */
  hl_name: SafeHTMLString
  /**
   * HTML escaped and truncated description with an <em> around the matching substring.
   */
  hl_trunc_description: SafeHTMLString
  name: string
  language: string
  mirror: boolean
  owned_by_organization: boolean
  public: boolean
  repo: {repository: Repository}
  sponsorable: boolean
  starred_by_current_user: boolean
  topics: string[]
  type: string
}

interface Repository {
  id: string
  name: string
  development_avatar?: string // Testing locally with test data
  owner_id: string
  owner_login: string
  updated_at: string
}

interface CuratedTopicResultItem {
  curated_topic: Topic
  linked_topic_name: string
}

export interface CommitsResults {
  type: 'commits'
  results: CommitResultItem[]
}

export interface CommitResultItem {
  id: string
  sha: string
  author_date: string
  hl_subject: SafeHTMLString
  hl_body: SafeHTMLString
  message: string
  repository: {repository: Repository}
  verification_status: VerificationStatus
  status_check_rollup: StatusCheckRollup
  help_url: string
  has_signature: boolean
  is_viewer: boolean
  key_expired: boolean
  key_id: string
  key_revoked: boolean
  signed_by_github: boolean
  signer_login: string
  signer_avatar_url: string
  signature_type: CommitSignatureType
  signature_certificate_subject?: CertificateAttributes
  signature_certificate_issuer?: CertificateAttributes
  signature_verification_reason: string
  checks_status_summary: string
  checks_header_state: string
  check_runs: CheckRun[]
  committer_attribution: boolean
  authors: Author[]
  committer: Author
  commit_author_tooltip: string
  issue_references: IssueReference[]
}

export interface IssueReference {
  title: string
  id: number
  permalink: string
  is_pull_request: boolean
  state: string
  state_reason?: string
  reviewable_state: string
  merged: boolean
}

export interface CheckRun {
  name: string
  state: string
  description: string
  target_url: string
  icon: string
  avatar_url: string
  avatar_description: string
  avatar_logo: string
  additional_context: string
  pending: boolean
  avatar_background_color: string
}

export interface StatusCheckRollup {
  state: string
}

export interface CommitAuthor {
  login: string
  displayName: string
  avatarUrl: string
  developmentAvatar?: string // Testing locally with test data
}

export interface Author {
  login: string
  display_name: string
  avatar_url: string
  development_avatar?: string // Testing locally with test data
}
export interface IssuesResults {
  type: 'issues'
  results: IssueResultItem[]
}

export interface IssueResultItem {
  author_name: string
  author_avatar_url: string
  development_avatar?: string // Testing locally with test data
  id: number
  issue: {
    issue: {
      pull_request_id: number | null
    }
  }
  repo: {repository: Repository}
  labels: SafeHTMLString[]
  num_comments: number
  number: number
  state: string
  state_reason?: string
  hl_title: SafeHTMLString
  hl_text: SafeHTMLString
  created: string
  reviewable_state: string
  merged: boolean
}

export interface PullRequestsResults {
  type: 'pullrequests'
  results: IssueResultItem[]
}

export interface DiscussionsResults {
  results: DiscussionResult[]
  type: 'discussions'
}

export interface DiscussionResult {
  body: string
  created: string
  /**
   * HTML escaped text with an <em> around the matching substring.
   */
  hl_text: SafeHTMLString
  /**
   * HTML escaped title with an <em> around the matching substring.
   */
  hl_title: SafeHTMLString
  id: number
  num_comments: number
  number: number
  repo: {repository: Repository}
  title: string
  url: string
  development_avatar?: string // Testing locally with test data
  updated: Date
  user_avatar_url: string
  user_id: number
  user_login: string
}

export interface PackagesResults {
  type: 'registrypackages'
  results: PackageResultItem[]
}

export interface PackageResultItem {
  id: number
  color?: string
  downloads: number
  name: string
  repo: Repository
  package_url: string
  public: boolean
  source: {
    visibility: string
    body: string
    package_type: string
    versions: Array<{
      version: string
      latest: boolean
    }>
  }
  summary: string
  topics: string[]
  updated_at: string
}

export interface TopicsResults {
  readonly type: 'topics'
  readonly results: readonly Topic[]
}

export interface Topic {
  readonly id: string
  readonly name: string
  readonly display_name: string | null
  readonly short_description: string | null
  readonly description: string | null
  /**
   * HTML escaped name with an <em> around the matching substring.
   */
  readonly hl_display_name: SafeHTMLString
  /**
   * HTML escaped description with an <em> around the matching substring.
   */
  readonly hl_short_description: SafeHTMLString | null
  readonly created_by: null
  readonly released: null
  readonly featured: boolean
  readonly curated: boolean
  readonly aliases: readonly string[]
  readonly related: readonly string[]
  readonly repository_count: number
  readonly repository_count_over_max_fetch_limit: boolean
  readonly starred_by_current_user: boolean
  readonly highlights: {
    readonly description?: readonly [string]
    readonly display_name?: readonly [string]
    readonly 'name.ngram'?: readonly [string]
  }

  readonly flagged: false
  readonly wikipedia_url: string | null
  readonly url: string | null
  readonly github_url: string | null
  readonly logo_url: string | null
  readonly development_logo_url?: string
  readonly has_logo_url: boolean
  readonly stargazer_count: number
  readonly applied_count: number
}

export interface WikisResults {
  type: 'wikis'
  results: WikiResultItem[]
}

export interface WikiResultItem {
  body: string
  filename: string
  format: string
  /**
   * HTML escaped body with an <em> around the matching substring.
   */
  hl_body: SafeHTMLString
  /**
   * HTML escaped title with an <em> around the matching substring.
   */
  hl_title: SafeHTMLString
  id: string
  path: string
  public: string
  repo: {repository: Repository}
  repo_id: string
  title: string
  updated_at: Date
}

export interface UsersResults {
  type: 'users'
  results: UserResult[]
}

export interface UserResult {
  avatar_url: string
  /**
   * HTML escaped login with an <em> around the matching substring.
   */
  hl_login: SafeHTMLString
  /**
   * HTML escaped name with an <em> around the matching substring.
   */
  hl_name?: SafeHTMLString
  /**
   * HTML escaped bio with an <em> around the matching substring.
   */
  hl_profile_bio?: SafeHTMLString
  followed_by_current_user: boolean
  development_avatar?: string // Testing locally with test data
  id: string
  is_current_user: boolean
  location: string
  login: string
  display_login: string
  repos: number
  followers: number
  name?: string
  profile_bio?: string
  sponsorable: boolean
}

export interface MarketplaceResults {
  type: 'marketplace'
  results: MarketplaceResult[]
}

export type MarketplaceResult = MarketplaceListingResult | RepositoryActionResult | RepositoryStackResult | ModelListing

interface BaseMarketplaceResult {
  readonly type: 'marketplace_listing' | 'repository_stack' | 'repository_action'
  readonly id: string
  readonly name: string
  readonly free: boolean
  readonly primary_category: string
  readonly secondary_category: string | null
  readonly is_verified_owner: boolean
  readonly slug: string
  readonly owner_login: string
  readonly resource_path: string
  readonly highlights: {
    readonly description?: string
    readonly 'name.ngram'?: string
  }
}

export interface MarketplaceListingResult extends BaseMarketplaceResult {
  readonly type: 'marketplace_listing'
  readonly installation_count: number
  readonly full_description: string
  readonly short_description: string
  readonly extended_description: string
  readonly development_logo?: string // Testing locally with test data
  readonly listing_logo_url: string
  readonly state: 'verified' | 'unverified'
  readonly recommended: boolean
  readonly highlights: BaseMarketplaceResult['highlights'] & {
    readonly short_description?: string
    readonly full_description?: string
  }
  readonly marketplace_listing: {readonly listing: MarketplaceListing}
}

export interface RepositoryActionResult extends BaseMarketplaceResult {
  readonly type: 'repository_action'
  readonly description: string
  readonly stars: number
  readonly icon_svg: SafeHTMLString | null
  readonly repository_action: {readonly repository_action: RepositoryAction}
}

export interface RepositoryStackResult extends BaseMarketplaceResult {
  readonly type: 'repository_stack'
  readonly description: string
  readonly stars: number
  readonly icon_svg: SafeHTMLString | null
  readonly repository_stack: {readonly repository_stack: RepositoryStack}
}

export interface MarketplaceListing {
  readonly id: number
  readonly state: number
  readonly name: string
  readonly slug: string
  readonly short_description: string
  readonly full_description: string
  readonly extended_description: string
  readonly primary_category_id: number
  readonly secondary_category_id: number
  readonly privacy_policy_url: string
  readonly tos_url: string
  readonly company_url: string
  readonly status_url: string
  readonly support_url: string
  readonly documentation_url: string
  readonly pricing_url: string
  readonly bgcolor: string
  readonly light_text: boolean
  readonly learn_more_url: string
  readonly installation_url: string
  readonly how_it_works: string
  readonly hero_card_background_image_id: number
  readonly technical_email: string
  readonly marketing_email: string
  readonly finance_email: string
  readonly direct_billing_enabled: boolean
  readonly by_github: boolean
  readonly security_email: string
  readonly listable_type: 'Integration' | 'OauthApplication'
  readonly listable_id: number
}

export interface RepositoryAction {
  readonly id: number
  readonly path: string
  readonly name: string
  readonly description: string
  readonly icon_name: string
  readonly color: string
  readonly featured: boolean
  readonly repository_id: number
  readonly rank_multiplier: number
  readonly slug: string
  readonly security_email: string
}

export interface RepositoryStack {
  readonly id: number
  readonly color: string
  readonly icon_name: string
}

export interface HomePayload {
  type: 'home'
  helpUrl: string
  logged_in?: boolean
  cookie_consent_enabled?: boolean
}

export const FacetKindLanguage = 'FACET_KIND_LANGUAGE'
export const FacetKindPath = 'FACET_KIND_PATH'
export const FacetKindRepo = 'FACET_KIND_REPO'
export const FacetKindFilter = 'filter'
export const FacetKindState = 'state'
export const FacetKindPackageType = 'FACET_KIND_PACKAGE_TYPE'

const SortOrderDesc = 'desc'
const SortOrderAsc = 'asc'

export type SortItem = {
  label: string
  sort: string
  order: string
}

export const DefaultSortItem: SortItem = {
  label: 'Best match',
  sort: '',
  order: SortOrderDesc,
}

const NewestSortItems: SortItem[] = [
  {
    label: 'Newest',
    sort: 'created',
    order: SortOrderDesc,
  },
  {
    label: 'Oldest',
    sort: 'created',
    order: SortOrderAsc,
  },
]

const RecentlyUpdatedSortItems: SortItem[] = [
  {
    label: 'Recently updated',
    sort: 'updated',
    order: SortOrderDesc,
  },
  {
    label: 'Least recently updated',
    sort: 'updated',
    order: SortOrderAsc,
  },
]

export const RepoSortItems: SortItem[] = [
  DefaultSortItem,
  {
    label: 'Most stars',
    sort: 'stars',
    order: SortOrderDesc,
  },
  {
    label: 'Fewest stars',
    sort: 'stars',
    order: SortOrderAsc,
  },
  {
    label: 'Most forks',
    sort: 'forks',
    order: SortOrderDesc,
  },
  {
    label: 'Fewest forks',
    sort: 'forks',
    order: SortOrderAsc,
  },
  ...RecentlyUpdatedSortItems,
]

export const CodeSortItems: SortItem[] = [
  DefaultSortItem,
  {
    label: 'Recently indexed',
    sort: 'indexed',
    order: SortOrderDesc,
  },
  {
    label: 'Least recently indexed',
    sort: 'indexed',
    order: SortOrderAsc,
  },
]

export const CommitSortItems: SortItem[] = [
  DefaultSortItem,
  {
    label: 'Recently committed',
    sort: 'committer-date',
    order: SortOrderDesc,
  },
  {
    label: 'Least recently committed',
    sort: 'committer-date',
    order: SortOrderAsc,
  },
  {
    label: 'Recently authored',
    sort: 'author-date',
    order: SortOrderDesc,
  },
  {
    label: 'Least recently authored',
    sort: 'author-date',
    order: SortOrderAsc,
  },
]

export const IssueSortItems: SortItem[] = [
  DefaultSortItem,
  {
    label: 'Most commented',
    sort: 'comments',
    order: SortOrderDesc,
  },
  {
    label: 'Least commented',
    sort: 'comments',
    order: SortOrderAsc,
  },
  ...NewestSortItems,
  ...RecentlyUpdatedSortItems,
]

export const DiscussionSortItems: SortItem[] = [
  DefaultSortItem,
  {
    label: 'Highest score',
    sort: 'top',
    order: SortOrderDesc,
  },
  {
    label: 'Lowest score',
    sort: 'top',
    order: SortOrderAsc,
  },
  {
    label: 'Most commented',
    sort: 'comments',
    order: SortOrderDesc,
  },
  {
    label: 'Least commented',
    sort: 'comments',
    order: SortOrderAsc,
  },
  {
    label: 'Newest',
    sort: 'date_created',
    order: SortOrderDesc,
  },
  {
    label: 'Oldest',
    sort: 'date_created',
    order: SortOrderAsc,
  },
  ...RecentlyUpdatedSortItems,
]

export const PackageSortItems: SortItem[] = [
  DefaultSortItem,
  {
    label: 'Most downloads',
    sort: 'downloads',
    order: SortOrderDesc,
  },
  {
    label: 'Fewest downloads',
    sort: 'downloads',
    order: SortOrderAsc,
  },
  ...NewestSortItems,
  ...RecentlyUpdatedSortItems,
]

export const UserSortItems: SortItem[] = [
  DefaultSortItem,
  {
    label: 'Most followers',
    sort: 'followers',
    order: SortOrderDesc,
  },
  {
    label: 'Fewest followers',
    sort: 'followers',
    order: SortOrderAsc,
  },
  {
    label: 'Most recently joined',
    sort: 'joined',
    order: SortOrderDesc,
  },
  {
    label: 'Least recently joined',
    sort: 'joined',
    order: SortOrderAsc,
  },
  {
    label: 'Most repositories',
    sort: 'repositories',
    order: SortOrderDesc,
  },
  {
    label: 'Fewest repositories',
    sort: 'repositories',
    order: SortOrderAsc,
  },
]

export const WikiSortItems: SortItem[] = [DefaultSortItem, ...RecentlyUpdatedSortItems]

export const FocusHintPrefix = 'blackbird-'

export interface CustomScope {
  name: string
  query: string
}

// The bundler does not like having a module that does not export any runtime code
export {}
