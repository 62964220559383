import {Box, SplitPageLayout, Pagination, Link} from '@primer/react'

import {
  Code,
  Commits,
  Discussions,
  LegacyCode,
  Issues,
  Topics,
  MarketplaceListings,
  Packages,
  PullRequests,
  Repositories,
  Users,
  Wikis,
} from '../components/results'

import {
  BookIcon,
  CodeIcon,
  CommentDiscussionIcon,
  GitCommitIcon,
  IssueOpenedIcon,
  MilestoneIcon,
  PeopleIcon,
  GitPullRequestIcon,
  PackageIcon,
  RepoIcon,
  TelescopeIcon,
} from '@primer/octicons-react'

import type React from 'react'
import {useCallback, useState, useEffect, useMemo} from 'react'
import {useRoutePayload} from '@github-ui/react-core/use-route-payload'
import HoverCard from '../../react-shared/HoverCard'
import {useLocation} from 'react-router-dom'
import {getUrl, searchPath} from '@github-ui/paths'
import {FacetsPane} from '../components/FacetsPane'
import NoResults from '../components/NoResults'
import SearchError from '../components/SearchError'
import {SearchSubHeader} from '../components/SearchSubHeader'
import CodeItemsSkeleton from '../components/skeletons/CodeItemsSkeleton'
import PrimarySuggestions from '../components/suggestions/PrimarySuggestions'
import SecondarySuggestions from '../components/suggestions/SecondarySuggestions'
import {useSearchResultCounts, type SearchKind} from '../hooks/use-search-result-counts'
import {useSoftNavigate} from '../hooks/use-soft-navigate'
import type {SearchResults, HomePayload, SearchResponse, SearchResultsType} from '../types/blackbird-types'
import {assertNever} from '../utilities/assert-never'
import {SingleSignOnBanner} from '@github-ui/single-sign-on-banner'

import Home from './Home'
import {
  chooseSearchType,
  mapSearchTypeToURLParam,
  parseString,
  SearchType,
  type SearchTypeURLParameter,
} from '../../../../components/search/parsing/parsing'
import {extractRepoOrgScopes, restrictToScopedOrgs} from '../../../../components/search/parsing/common'
import SearchTypeError, {getSearchTypeError, type SearchTypeErrorMessage} from '../components/SearchTypeError'
import {useSearchAnalytics} from '../hooks/use-search-analytics'
import LoggedInContext from '../contexts/LoggedInContext'
import {Banner} from '@primer/react/experimental'

import styles from './Search.module.css'

export default function Search() {
  const payload = useRoutePayload<SearchResults | HomePayload | null>()
  const [searchTypeError, setSearchTypeError] = useState<SearchTypeErrorMessage | null>(null)
  const location = useLocation()
  const navigateToPage = useSoftNavigate(searchPath)
  const search = useMemo(() => new URLSearchParams(location.search), [location.search])
  const searchType = search.get('type')
  const searchQuery = search.get('q')
  const scopes = search.get('saved_searches')
  const {sendSearchNextPageNavigation, sendSearchBackNavigation} = useSearchAnalytics()

  const loggedInContextValue = !!(payload && payload.logged_in)

  // Determine whether the user reached the page via back button
  useEffect(() => {
    const entries = window?.performance?.getEntries && window.performance.getEntries()
    if (entries && entries.length > 0 && (entries[0] as PerformanceNavigationTiming).type === 'back_forward') {
      if (payload?.type === 'code') {
        sendSearchBackNavigation(payload?.query_id)
      }
    }
  }, [sendSearchBackNavigation, payload])

  const isLoading = !payload
  const repoSearch = useMemo(() => {
    const astNode = parseString(searchQuery || '')
    if (payload?.type === 'home') {
      return false
    }

    // Only return true if we are scoped to exactly one repository
    const repoScopes = extractRepoOrgScopes(astNode)
    if (repoScopes.length === 1 && repoScopes[0]?.kind === 'repo') {
      return true
    }

    return false
  }, [searchQuery, payload?.type])

  const protectedOrgs = useMemo(() => {
    if (
      !payload ||
      payload.type === 'home' ||
      !payload.protected_org_logins ||
      payload.protected_org_logins.length === 0
    ) {
      return []
    }
    const astNode = parseString(searchQuery || '')
    return restrictToScopedOrgs(astNode, payload?.protected_org_logins)
  }, [payload, searchQuery])

  const searchKinds = useMemo(
    () =>
      [
        {
          name: 'code',
          readableName: 'code',
          readableNamePlural: 'code',
          icon: CodeIcon,
        } as const,
        repoSearch
          ? null
          : ({
              name: 'repositories',
              readableName: 'repository',
              readableNamePlural: 'repositories',
              icon: RepoIcon,
            } as const),
        {
          name: 'issues',
          readableName: 'issue',
          readableNamePlural: 'issues',
          icon: IssueOpenedIcon,
        } as const,
        {
          name: 'pullrequests',
          readableName: 'pull request',
          readableNamePlural: 'pull requests',
          icon: GitPullRequestIcon,
        } as const,
        {
          name: 'discussions',
          readableName: 'discussion',
          readableNamePlural: 'discussions',
          icon: CommentDiscussionIcon,
        } as const,
        repoSearch
          ? null
          : ({name: 'users', readableName: 'user', readableNamePlural: 'users', icon: PeopleIcon} as const),
        {name: 'commits', readableName: 'commit', readableNamePlural: 'commits', icon: GitCommitIcon} as const,
        {
          name: 'registrypackages',
          readableName: 'package',
          readableNamePlural: 'packages',
          icon: PackageIcon,
        } as const,
        {name: 'wikis', readableName: 'wiki', readableNamePlural: 'wikis', icon: BookIcon} as const,
        repoSearch
          ? null
          : ({name: 'topics', readableName: 'topic', readableNamePlural: 'topics', icon: MilestoneIcon} as const),
        repoSearch
          ? null
          : ({
              name: 'marketplace',
              readableName: 'marketplace',
              readableNamePlural: 'marketplace',
              icon: TelescopeIcon,
            } as const),
      ].filter((x: SearchKind | null): x is SearchKind => x !== null) as SearchKind[],
    [repoSearch],
  )
  const resultKind = searchKinds.find(kind => kind.name === searchType) || searchKinds[0]!

  const [isLoadingCounters, counts] = useSearchResultCounts({
    searchType: searchType as SearchResultsType,
    kinds: searchKinds,
    query: searchQuery,
    scopes,
    payload: payload as SearchResponse,
  })

  useEffect(() => {
    if (!searchType && searchQuery && payload?.type !== 'home') {
      // No search type is specified in the URL - that probably means somebody URL hacked
      // their way here. Choose a search type based on the query and redirect to there.
      let query = search.get('q')
      const ast = parseString(query || '')
      const preferredType = chooseSearchType(ast, !!payload?.logged_in)

      // Since they're URL hacking, they may have specified language using `&l=...`. In that case
      // rewrite it into the query itself. The `&l` parameter only has an effect in non-code search.
      if (preferredType === 'code') {
        let lang = search.get('l')
        if (lang) {
          if (lang.includes(' ')) lang = `"${lang}"`
          query = `${query} lang:${lang}`
        }
      }

      // We tried, and still don't know the preferred type. Default to code search and don't redirect or else
      // we will get caught in a loop.
      if (preferredType !== SearchType.Unknown) {
        navigateToPage(
          undefined,
          {q: query, type: mapSearchTypeToURLParam(preferredType), l: undefined, p: undefined},
          {replace: true},
        )
      }
      return
    }

    setSearchTypeError(
      getSearchTypeError(searchType as SearchTypeURLParameter, searchQuery as string, !!payload?.logged_in),
    )
  }, [payload, search, searchType, searchQuery, navigateToPage])

  const onPageChange = useCallback(
    (e: React.MouseEvent, p: number) => {
      window.scrollTo(0, 0)
      navigateToPage(undefined, {p})
      if (payload?.type === 'code') {
        sendSearchNextPageNavigation(payload.query_id, p)
      }
      e.preventDefault()
    },
    [navigateToPage, sendSearchNextPageNavigation, payload],
  )

  if (payload?.type === 'home') {
    return <Home />
  }

  const isLoggedOutCodeResults = !payload?.logged_in && payload?.type === 'code'
  const type = payload?.type
  const isCode = type === 'code'
  const showPagination =
    payload && payload.page_count > 1 && !!payload.page && payload.page_count > 1 && !isLoggedOutCodeResults
  const hasErrors = (payload && payload.errors?.length > 0) || searchTypeError !== null

  let title = ''
  if (payload) {
    title += `${payload.type} `
  }
  title += `Search Results`
  if (searchQuery) {
    title += ` · ${searchQuery}`
  }
  return (
    <LoggedInContext.Provider value={loggedInContextValue}>
      <Box
        sx={{bg: 'canvas.default', height: hasErrors ? 'auto' : '100%', display: 'flex', flexDirection: 'column'}}
        className="search-results-page"
      >
        <style>{searchMatchCss}</style>
        <h1 className="sr-only">{title}</h1>
        <SplitPageLayout className={styles.SplitPageLayout}>
          <FacetsPane
            isLoading={isLoading}
            isLoadingCounters={isLoadingCounters}
            searchKinds={counts}
            resultCount={payload && payload.result_count}
            selectedType={type || guessTypeFromSearch()}
            facets={payload && payload.facets}
            searchType={payload && payload.type}
          />
          <SplitPageLayout.Content as="div" padding="none" width="full" className={styles.SplitPageLayout_Content}>
            <TwoColumnLayout
              fullWidth={isCode || !payload || isEmpty(payload)}
              fullHeight={isLoggedOutCodeResults}
              primarySuggestion={payload && <PrimarySuggestions payload={payload} />}
              secondarySuggestion={payload && <SecondarySuggestions payload={payload} />}
              header={
                payload && (
                  <SearchSubHeader
                    payload={payload}
                    isLoading={isLoading}
                    isLoadingCounters={isLoadingCounters}
                    searchKinds={counts}
                    selectedType={type || guessTypeFromSearch()}
                  />
                )
              }
              notices={[
                payload && (
                  <SingleSignOnBanner
                    key="single-sign-on"
                    protectedOrgs={protectedOrgs}
                    redirectURI={loc =>
                      `/search/refresh_blackbird_caches?return_to=${encodeURIComponent(
                        `/search${loc.search + loc.hash}`,
                      )}`
                    }
                  />
                ),
                payload && <Errors key="errors" errorType={searchTypeError} payload={payload} />,
                payload?.warn_limited_results && (
                  <Banner key="warn_limited_results" variant="warning" hideTitle title="Advanced search suggestion">
                    <Banner.Description>
                      Not finding the results your looking for? Try adding the <code>org:</code>, <code>repo:</code>, or{' '}
                      <code>user:</code> parameters for a more precise set of search results. To see all the parameters
                      check out our{' '}
                      <Link inline href="/search/advanced">
                        advanced search tool
                      </Link>
                    </Banner.Description>
                  </Banner>
                ),
              ]}
              pagination={
                showPagination && (
                  <Box sx={{pb: [2, 2, 2, 0]}}>
                    <Pagination
                      pageCount={payload.page_count}
                      currentPage={payload.page}
                      onPageChange={onPageChange}
                      hrefBuilder={p => getUrl(searchPath, undefined, {p}).href}
                    />
                  </Box>
                )
              }
              columnLeft={
                <>
                  {!payload ? (
                    <CodeItemsSkeleton />
                  ) : isEmpty(payload) && !isLoggedOutCodeResults ? (
                    <NoResults resultKind={resultKind} searchKinds={counts} isLoadingCounters={isLoadingCounters} />
                  ) : (
                    <Box
                      data-hpc
                      sx={{
                        width: '100%',
                        minWidth: 0,
                        height: isLoggedOutCodeResults ? '100%' : undefined,
                      }}
                    >
                      <SearchResultsComponent payload={payload} isRepoSearch={repoSearch} />
                    </Box>
                  )}
                </>
              }
            />
          </SplitPageLayout.Content>
        </SplitPageLayout>
        <HoverCard />
      </Box>
    </LoggedInContext.Provider>
  )
}

function TwoColumnLayout({
  columnLeft,
  primarySuggestion,
  secondarySuggestion,
  header,
  fullWidth,
  fullHeight,
  notices,
  pagination,
}: {
  header: React.ReactNode
  columnLeft: React.ReactNode
  primarySuggestion?: React.ReactNode
  secondarySuggestion?: React.ReactNode
  notices: Array<React.ReactNode | null>
  fullWidth: boolean
  fullHeight?: boolean
  pagination?: React.ReactNode
}) {
  return (
    <Box
      sx={{
        display: 'flex',
        px: [3, 3, 4, 4],
        py: 3,
        alignItems: 'flex-start',
        height: fullHeight ? '100%' : undefined,
      }}
    >
      <Box
        sx={{
          maxWidth: '100%',
          minWidth: 0,
          height: fullHeight ? '100%' : undefined,
          overflow: 'hidden',
          flex: 2,
          display: 'flex',
          flexDirection: 'column',
        }}
      >
        {header}
        <Box
          sx={{
            display: 'flex',
            flexDirection: 'column',
            pt: 3,
            gap: 3,
            '&:empty': {
              display: 'none',
            },
          }}
        >
          {notices}
        </Box>
        <Box
          sx={{
            display: ['inline-block', 'inline-block', 'inline-block', 'none'],
            pt: 3,
            '&:empty': {
              display: 'none',
            },
          }}
        >
          {primarySuggestion}
        </Box>
        <Box sx={{pt: 3, height: fullHeight ? '100%' : undefined}}>{columnLeft}</Box>
        <Box sx={{py: [3, 3, 4, 4]}}>{pagination}</Box>
        {!fullWidth && (
          <Box sx={{display: ['grid', 'grid', 'grid', 'none'], gap: 3, pt: [0, 0, 0, 3]}}>{secondarySuggestion}</Box>
        )}
      </Box>
      {!fullWidth && (
        <Box
          sx={{
            display: ['none', 'none', 'none', 'flex'],
            flexDirection: 'column',
            ml: [0, 0, 0, 4],
            gap: 3,
            width: '100%',
            pt: [0, 0, 0, 20],
            mt: [0, 0, 0, 4],
            maxWidth: ['100%', '100%', '100%', '25%', '25%'],
            minWidth: ['100%', '100%', '100%', 260, 340],
            '&:empty': {
              display: 'none',
            },
          }}
        >
          {primarySuggestion}
          {secondarySuggestion}
        </Box>
      )}
    </Box>
  )
}

function Errors({errorType, payload}: {errorType: SearchTypeErrorMessage | null; payload: SearchResults}) {
  if (errorType) {
    return <SearchTypeError {...errorType} />
  }

  if (payload?.errors && payload.errors.length > 0) {
    // Show at most two errors. More than that is overwhelming
    return (
      <>
        {payload.errors.slice(0, 2).map((error, idx) => {
          // eslint-disable-next-line @eslint-react/no-array-index-key
          return <SearchError error={error} key={idx} index={idx} />
        })}
      </>
    )
  }
  return null
}

function SearchResultsComponent({payload, isRepoSearch}: {payload: SearchResults; isRepoSearch: boolean}) {
  switch (payload.type) {
    case 'code':
      return <Code isRepoSearch={isRepoSearch} results={payload} />
    case 'codelegacy':
      return <LegacyCode results={payload} />
    case 'commits':
      return <Commits results={payload} />
    case 'discussions':
      return <Discussions results={payload} />
    case 'topics':
      return <Topics results={payload} />
    case 'registrypackages':
      return <Packages results={payload} />
    case 'repositories':
      return <Repositories results={payload} />
    case 'users':
      return <Users results={payload} />
    case 'wikis':
      return <Wikis results={payload} />
    case 'issues':
      return <Issues results={payload} />
    case 'pullrequests':
      return <PullRequests results={payload} />
    case 'marketplace':
      return <MarketplaceListings results={payload} />
    default:
      assertNever(payload)
  }
}

const searchMatchCss = `
  .search-results-page .search-match em {
    font-style: normal;
    font-weight: 600;
  }

  .search-results-page .search-title .search-match em {
    font-style: normal;
    font-weight: 700;
  }
`

function isEmpty(payload: SearchResults) {
  return payload.results?.length === 0
}

function guessTypeFromSearch() {
  return new URL(document.location.href, window.location.origin).searchParams.get('type')?.toLowerCase()
}

try{ Search.displayName ||= 'Search' } catch {}
try{ TwoColumnLayout.displayName ||= 'TwoColumnLayout' } catch {}
try{ Errors.displayName ||= 'Errors' } catch {}
try{ SearchResultsComponent.displayName ||= 'SearchResultsComponent' } catch {}